import Vue from 'vue'
import VueRouter from 'vue-router'
// eslint-disable-next-line import/no-cycle
import store from '@/store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Inicio',
        breadcrumb: [
          {
            text: 'Inicio',
            active: true,
          },
        ],
      },
    },
    {
      path: '/calendario',
      name: 'calendario',
      component: () => import('@/views/ausentismo/Index.vue'),
      meta: {
        pageTitle: 'Calendario',
        breadcrumb: [
          {
            text: 'Calendario',
            active: true,
          },
        ],
      },
    },
    {
      path: '/reclamos',
      name: 'reclamos',
      component: () => import('@/views/reclamos/Reclamos.vue'),
      meta: {
        pageTitle: 'Reclamos',
        breadcrumb: [
          {
            text: 'Reclamos',
            active: true,
          },
        ],
      },
    },
    {
      path: '/gestionar-reclamos',
      name: 'gestionar-reclamos',
      component: () => import('@/views/gestion-reclamos/GestionReclamos.vue'),
      meta: {
        contentRenderer: 'sidebar-left',
        contentClass: 'todo-application',
        validate: true,
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.beforeEach(async (to, from, next) => {
  await store.dispatch('auth/userIsLoggedIn')
  await store.dispatch('auth/checkExpirationToken')
  await store.dispatch('auth/leaderExists')
  const isLeader = await store.dispatch('auth/userIsLeader')

  // const currentUser = store.getters['auth/currentUser']
  // const userProfileCompleted = store.getters['auth/getStatusProfile']
  // const userContactInformationCompleted = store.getters['auth/getStatusContactInformation']

  if (!store.getters['auth/isLoggedIn']) {
    to.name === 'login' ? next() : next({ name: 'login' })
    return
  }

  // const validateByStore = store.getters['auth/getValidationIdentity']
  const routeInclude = ['login', 'auth-register']
  // const isUserValidate = Boolean(currentUser?.perfil?.validacion_identidad) || validateByStore

  if (to.name === 'login' && store.getters['auth/isLoggedIn']) {
    next({ name: 'home' })
  } else if (!routeInclude.includes(to.name) && !store.getters['auth/isLoggedIn']) {
    next({ name: 'login' })
  } else if (to.meta.validate) {
    const isValid = isLeader
    isValid ? next() : next({ name: 'home' })
  } else {
    next()
  }
})

router.afterEach(() => {
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) appLoading.style.display = 'none'
})

export default router
