const SET_EVENTS_DATA = 'SET_EVENTS_DATA'
const SET_EVENTS_DATA_PREV = 'SET_EVENTS_DATA_PREV'
const SET_EVENTS_DATA_NEXT = 'SET_EVENTS_DATA_NEXT'
const SET_CURRENT_MONTH = 'SET_CURRENT_MONTH'
const SET_PREV_MONTH = 'SET_PREV_MONTH'
const SET_NEXT_MONTH = 'SET_NEXT_MONTH'
const SET_LAST_DAY = 'SET_LAST_DAY'
const SET_FIRST_DAY = 'SET_FIRST_DAY'
const SET_ALL_EVENTS = 'SET_ALL_EVENTS'
const SET_SHOW_LOADING_EVENTS = 'SET_SHOW_LOADING_EVENTS'
const SET_SHOW_LOADING_CLAIMS = 'SET_SHOW_LOADING_CLAIMS'

const state = {
  eventsData: null,
  eventsDataPrev: null,
  eventsDataNext: null,
  currentMonth: null,
  prevMonth: null,
  nextMonth: null,
  lastDay: null,
  firstDay: null,
  allEvents: null,
  showLoadingEvents: false,
  showLoadingClaims: false,
}

const mutations = {
  [SET_CURRENT_MONTH](state, month) {
    state.currentMonth = month;
  },
  [SET_PREV_MONTH](state, month) {
    state.prevMonth = month;
  },
  [SET_NEXT_MONTH](state, month) {
    state.nextMonth = month;
  },
  [SET_EVENTS_DATA](state, data) {
    state.eventsData = data;
  },
  [SET_EVENTS_DATA_PREV](state, data) {
    state.eventsDataPrev = data;
  },
  [SET_EVENTS_DATA_NEXT](state, data) {
    state.eventsDataNext = data;
  },
  [SET_LAST_DAY](state, day) {
    state.lastDay = day;
  },
  [SET_FIRST_DAY](state, day) {
    state.firstDay = day;
  },
  [SET_ALL_EVENTS](state, events) {
    state.allEvents = events;
  },
  [SET_SHOW_LOADING_EVENTS](state, value) {
    state.showLoadingEvents = value
  },
  [SET_SHOW_LOADING_CLAIMS](state, value) {
    state.showLoadingClaims = value
  }
}

const actions = {
  setEventsData({ commit }, value) {
    commit(SET_EVENTS_DATA, value)
  },
  setEventsDataPrev({ commit }, value) {
    commit(SET_EVENTS_DATA_PREV, value)
  },
  setEventsDataNext({ commit }, value) {
    commit(SET_EVENTS_DATA_NEXT, value)
  },
  setCurrentMonth({ commit }, value) {
    commit(SET_CURRENT_MONTH, value)
  },
  setPrevMonth({ commit }, value) {
    commit(SET_PREV_MONTH, value)
  },
  setNextMonth({ commit }, value) {
    commit(SET_NEXT_MONTH, value)
  },
  setLastDay({ commit }, value) {
    commit(SET_LAST_DAY, value)
  },
  setFirstDay({ commit }, value) {
    commit(SET_FIRST_DAY, value)
  },
  setAllEvents({ commit }, value) {
    commit(SET_ALL_EVENTS, value)
  },
  setShowLoadingEvent({ commit }, value) {
    commit(SET_SHOW_LOADING_EVENTS, value)
  },
  setShowLoadingClaims({ commit }, value) {
    commit(SET_SHOW_LOADING_CLAIMS, value)
  },
}

const getters = {
  getEventsData: state => state.eventsData,
  getEventCurrentMonth: state => state.currentMonth,
  getEventPrevMonth: state => state.prevMonth,
  getEventNextMonth: state => state.nextMonth,
  getLastDay: state => state.lastDay,
  getFirstDay: state => state.firstDay,
  getAllEvents: state => state.allEvents,
  getShowLoadingEvents: state => state.showLoadingEvents,
  getShowLoadingClaims: state => state.showLoadingClaims,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
