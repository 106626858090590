import router from '@/router'
import store from '@/store'
import api from '@/api'
import Vue from 'vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';


const SET_LOGGED_IN_COGNITO = 'SET_LOGGED_IN_COGNITO'
const SET_LOGGED_OUT_COGNITO = 'SET_LOGGED_OUT_COGNITO'
const SET_VALIDATION_IDENTITY = 'SET_VALIDATION_IDENTITY'
const SET_PROFILE_COMPLETED = 'SET_PROFILE_COMPLETED'
const SET_CONTACT_INFORMATION_COMPLETED = 'SET_CONTACT_INFORMATION_COMPLETED'
const SET_IS_LEADER = 'SET_IS_LEADER'
const SET_LEADER_EXISTS = 'SET_LEADER_EXISTS'

const state = {
  isLoggedIn: false,
  user: null,
  access_token: null,
  id_token: null,
  refresh_token: null,
  expires_in: null,
  tokenExpirationTime: null,
  token_type: null,
  validacion_identidad: false,
  profile_completed: false,
  contact_information_completed: false,
  isLeader: false,
  leaderExists: false,
}

const mutations = {
  [SET_LEADER_EXISTS](state, value) {
    state.leaderExists = value
  },
  [SET_IS_LEADER](state, value) {
    state.isLeader = value
  },
  [SET_VALIDATION_IDENTITY](state, value) {
    state.validacion_identidad = value
  },
  [SET_PROFILE_COMPLETED](state, value) {
    state.profile_completed = value
  },
  [SET_CONTACT_INFORMATION_COMPLETED](state, value) {
    state.contact_information_completed = value
  },
  [SET_LOGGED_IN_COGNITO](state, payload) {
    state.isLoggedIn = true
    state.user = payload.data
    state.access_token = payload.data_cognito.access_token
    state.id_token = payload.data_cognito.id_token
    state.refresh_token = payload.data_cognito.refresh_token
    state.expires_in = payload.data_cognito.expires_in
    state.token_type = payload.data_cognito.token_type
    state.tokenExpirationTime = payload.data_cognito.tokenExpirationTime
    localStorage.setItem('id_token', payload.data_cognito.id_token)
    localStorage.setItem('access_token', payload.data_cognito.access_token)
    localStorage.setItem('refresh_token', payload.data_cognito.refresh_token)
    localStorage.setItem('user', JSON.stringify(payload.data))
    localStorage.setItem('data_cognito', JSON.stringify(payload.data_cognito))
  },
  [SET_LOGGED_OUT_COGNITO](state) {
    state.isLoggedIn = false
    state.user = null
    state.id_token = null
    state.refresh_token = null
    state.access_token = null
    state.expires_in = null
    state.tokenExpirationTime = null
    state.token_type = null
    localStorage.removeItem('user')
    localStorage.removeItem('id_token')
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
    localStorage.removeItem('data_validate_identity')
    localStorage.removeItem('data_cognito')
    localStorage.removeItem('last_month')
    localStorage.removeItem('events_data')
  },
}

const actions = {
  loginCognito({ commit }, params) {
    return new Promise(async (resolve, reject) => {
      const { codigo } = params

      const baseUrlUsers = process.env.VUE_APP_USERS_API_URL

      try {
        const response = await api.post(`${baseUrlUsers}/login`, {codigo})

        const data = response?.data?.datauser
        const data_cognito = response?.data?.tokens

        const dataArrayMeucci = data['custom:data_meucci'].split('|');

        const isLeader = dataArrayMeucci[dataArrayMeucci.length - 2];
        const leaderExists = dataArrayMeucci[dataArrayMeucci.length - 1];

        if (isLeader === 'LIDERES' || isLeader === 'GERENTES') {
          commit(SET_IS_LEADER, true)
        }

        if(leaderExists === 'LIDER ACTIVO') {
          commit(SET_LEADER_EXISTS, true)
        }

        if (response.data.error) {
          if (response.data.inactive) {
            setTimeout(() => {
              store.dispatch('auth/logoutCognito');
            }, 1000);
            resolve({ error: true, message: 'No puede acceder a esta aplicación', inactive: true })
            return
          }
          setTimeout(() => {
            store.dispatch('auth/logoutCognito');
          }, 3000);
          resolve({ error: true, message: `${response?.data?.message}` })
          return
        }

        const timeExpiresIn = data_cognito.expires_in
        const tokenExpirationTime = new Date().getTime() + timeExpiresIn * 1000
        data_cognito.tokenExpirationTime = tokenExpirationTime

        commit(SET_LOGGED_IN_COGNITO, { data, data_cognito })
        resolve({ success: true, title: 'Autenticación Exitosa', data })
        router.push('/')
      } catch (error) {
        Vue.prototype.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: `${error?.response?.data?.message}`,
          },
        });
        reject({error: true, message: `${error?.message}`, error_data: error?.response?.data})
      }
    })
  },
  registerCognito(data) {
    return new Promise(async (resolve, reject) => {
      // Lógica de registro
      try {
        const response = await api.post('/auth/register', data)
        resolve({ success: true, message: response.data.msg })
        router.push('/login')
      } catch (error) {
        reject(error)
      }
    })
  },
  userIsLeader({ commit }) {
    const user = JSON.parse(localStorage.getItem('user'))
    if(user){
      const dataArrayMeucci = user['custom:data_meucci'].split('|');
      const isLeader = dataArrayMeucci[dataArrayMeucci.length - 2];
      if (isLeader === 'LIDERES' || isLeader === 'GERENTES') {
        commit(SET_IS_LEADER, true)
        return true
      }
    }
    return false
  },
  leaderExists({ commit }) {
    const user = JSON.parse(localStorage.getItem('user'))
    if(user){
      const dataArrayMeucci = user['custom:data_meucci'].split('|');
      const leaderExists = dataArrayMeucci[dataArrayMeucci.length - 1];
      if (leaderExists === 'LIDER ACTIVO') {
        commit(SET_LEADER_EXISTS, true)
        return true
      }
    }
    return false
  },
  userIsLoggedIn({ commit }) {
    const user = JSON.parse(localStorage.getItem('user'))
    const cognito = JSON.parse(localStorage.getItem('data_cognito'))
    const payload = { data: user, data_cognito: cognito }
    if (user) {
      commit(SET_LOGGED_IN_COGNITO, payload)
      return true
    }
    return false
  },
  checkExpirationToken({ commit }) {
    const expirationTime = store.getters['auth/getTimeExpirationToken']
    const currentTime = new Date().getTime()
    if (expirationTime < currentTime) {
      commit(SET_LOGGED_OUT_COGNITO)
      if(expirationTime !== null) {
        Vue.prototype.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Advertencia',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
            text: 'Su sesión expiró',
          },
        });
        router.push('/login');
      }
      commit(SET_LOGGED_OUT_COGNITO)
    }
    return true
  },
  async checkStatusToken({ commit }) {
    const accessToken = localStorage.getItem('access_token')
    const user = JSON.parse(localStorage.getItem('user'))
    if(user){
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
        const id = user?.usuario?.id
        const response = await api.get(`/session/validate/${id}`, config)
        const data = response?.data
        if (data.multisesion === true) {
          Vue.prototype.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Advertencia',
              icon: 'AlertTriangleIcon',
              variant: 'warning',
              text: 'Iniciaron Sesión en otro Dispositivo',
            },
          });
          commit(SET_LOGGED_OUT_COGNITO)
        }
        if (data.token === false) {
          Vue.prototype.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Advertencia',
              icon: 'AlertTriangleIcon',
              variant: 'warning',
              text: `${data.message}`,
            },
          });
          commit(SET_LOGGED_OUT_COGNITO)
        }
      } catch (error) {
        console.log('error', error)
        Vue.prototype.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Algo Sucedió',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
            text: 'Estamos trabajando para corregirlo.',
          },
        });
        commit(SET_LOGGED_OUT_COGNITO)
      }
    }
    const expirationTime = store.getters['auth/getTimeExpirationToken']
    const currentTime = new Date().getTime()
    const fiveMinutesInMilliseconds = 5 * 60 * 1000

    return expirationTime >= currentTime + fiveMinutesInMilliseconds
  },
  updateValidationIdentity({ commit }, value) {
    commit(SET_VALIDATION_IDENTITY, value)
  },
  updateCompletedProfile({ commit }, value) {
    commit(SET_PROFILE_COMPLETED, value)
  },
  updateCompletedContactInformation({ commit }, value) {
    commit(SET_CONTACT_INFORMATION_COMPLETED, value)
  },
  logoutCognito({ commit }, forzed = false) {
    return new Promise(async () => {
      const baseUrl = process.env.VUE_APP_AUTH_BASE_URL
      const clientId = process.env.VUE_APP_AUTH_CLIENT_ID
      const redirectUri = process.env.VUE_APP_AUTH_REDIRECT_URI
      const logoutUrl = `${baseUrl}/logout?client_id=${clientId}&logout_uri=${redirectUri}`
      if(forzed) {
        setTimeout(() => {
          window.location.assign(logoutUrl);
        }, 3000);
      } else {
        window.location.assign(logoutUrl);
      }
      commit(SET_LOGGED_OUT_COGNITO)
    })
  },
}
const getters = {
  isLoggedIn: state => state.isLoggedIn,
  currentUser: state => state.user,
  getTimeExpirationToken: state => state.tokenExpirationTime,
  getValidationIdentity: state => state.validacion_identidad,
  getStatusProfile: state => state.profile_completed,
  getStatusContactInformation: state => state.contact_information_completed,
  isLeader: state => state.isLeader,
  leaderExists: state => state.leaderExists,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
